<template>
  <div class="language-switcher">
    <img
        v-for="language in languages"
        :key="language.code"
        :src="language.flag"
        :alt="$t('nav.language.switch', { languageName: language.name })"
        :class="['flag', { active: language.code === locale }]"
        @click="changeLanguage(language.code)"
        @keydown.enter="changeLanguage(language.code)"
        @keydown.space.prevent="changeLanguage(language.code)"
        role="button"
        :aria-label="$t('nav.language.switch', { languageName: language.name })"
        tabindex="0"
    />
  </div>
</template>


<script>
export default {
  data() {
    return {
      locale: this.$i18n.locale,
      languages: [
        { code: 'en', name: 'English', flag: require('@/assets/flags/gb.svg') },
        { code: 'de', name: 'Deutsch', flag: require('@/assets/flags/de.svg') },
        { code: 'gr', name: 'Greek', flag: require('@/assets/flags/gr.svg') },
      ],
    };
  },
  mounted() {
    const storedLocale = localStorage.getItem('locale');
    if (storedLocale) {
      this.$i18n.locale = storedLocale;
      this.locale = storedLocale; // Set locale in data
    }
  },
  methods: {
    changeLanguage(code) {
      this.locale = code; // Update locale in data
      this.$i18n.locale = code;
      localStorage.setItem('locale', code);
    }
  }
};
</script>

<style scoped>
.language-switcher {
  display: flex;
  align-items: center;
}

.flag {
  width: 30px;
  height: 20px;
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.2s, border 0.2s;
}

.flag:hover {
  transform: scale(1.1);
}

.flag.active {
  transform: scale(1.1);
  border-radius: 4px;
}
</style>
