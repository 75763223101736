<template>
  <div id="loading" :class="{ hide: isLoaded }">
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoaded: false
    };
  },
  methods: {
    showLoader() {
      this.isLoaded = false;
      const loadingElement = document.getElementById('loading');
      if (loadingElement) {
        loadingElement.style.display = 'flex';
        loadingElement.style.opacity = '1';
      }
    },
    hideLoader() {
      this.isLoaded = true;
      this.$nextTick(() => {
        setTimeout(() => {
          const loadingElement = document.getElementById('loading');
          if (loadingElement) {
            loadingElement.style.opacity = '0';
            setTimeout(() => {
              loadingElement.style.display = 'none';
            }, 500); // Zeit für die CSS-Transition
          }
        }, 100); // Kleine Verzögerung, um sicherzustellen, dass der DOM aktualisiert wurde
      });
    }
  }
};
</script>

<style scoped>
#loading {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  transition: opacity 0.5s ease-out;
  opacity: 1;
}

#loading.hide {
  opacity: 0;
  pointer-events: none;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
