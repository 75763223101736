<template>
  <div class="contact-section py-5">
    <div class="container">
      <h2 class="text-center mb-4">{{ $t('contact.title') }}</h2>
      <div class="row">
        <div class="col-md-6">
          <div id="map" class="map-container"></div>
        </div>
        <div class="col-md-6">
          <h3>{{ $t('contact.locationTitle') }}</h3>
          <p>{{ $t('contact.locationText') }}</p>
          <h3>{{ $t('contact.emailTitle') }}</h3>
          <p>panarentcar24@gmail.com</p>
          <h3>{{ $t('contact.phoneTitle') }}</h3>
          <p>{{ $t('contact.phoneText') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

export default {
  name: 'ContactSectionNew',
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    this.$nextTick(this.initMap);
  },
  beforeUnmount() {
    if (this.map) {
      this.map.remove();
    }
  },
  methods: {
    initMap() {
      if (this.map) return;

      this.map = L.map('map').setView([40.6401, 22.9444], 13);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map);

      const popupText = this.$t('common.pageTitle');

      L.marker([40.6401, 22.9444]).addTo(this.map)
          .bindPopup(popupText)
          .openPopup();
    }
  }
};
</script>

<style scoped>
.contact-section {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
}

.map-container {
  height: 400px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
}

.contact-section h2, .contact-section h3 {
  color: #2b384a;
}

.contact-section p {
  color: #555;
}
</style>
