<template>
  <div id="carouselTheSliderIndicators" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselTheSliderIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselTheSliderIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselTheSliderIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
      <button type="button" data-bs-target="#carouselTheSliderIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="@/assets/images/Thessaloniki_00.jpg" class="d-block w-100 carousel-image" alt="...">
      </div>
      <div class="carousel-item">
        <img src="@/assets/images/Thessaloniki_01.jpg" class="d-block w-100 carousel-image" alt="...">
      </div>
      <div class="carousel-item">
        <img src="@/assets/images/Thessaloniki_02.jpg" class="d-block w-100 carousel-image" alt="...">
      </div>
      <div class="carousel-item">
        <img src="@/assets/images/Thessaloniki_03.jpg" class="d-block w-100 carousel-image" alt="...">
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselTheSliderIndicators" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">{{ $t('common.prev')}}</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselTheSliderIndicators" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">{{ $t('common.next')}}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'TheSlider',
};
</script>

<style scoped>
.carousel-image {
  height: 50vh;
  object-fit: cover;
}
</style>
