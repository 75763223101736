<template>
  <footer class="bg-dark text-white text-center py-4">
    <div class="container">
      <p>&copy; {{ getCurrentYear() }} {{ $t('common.title')}} </p>
      <nav aria-label="Footer">
        <ul class="list-inline">
          <li class="list-inline-item">
            <router-link to="/privacy" class="text-white" :aria-label="$t('common.privacyPolicy')">{{ $t('common.privacyPolicy') }}</router-link>
          </li>
          <li class="list-inline-item">
            <router-link to="/imprint" class="text-white" :aria-label="$t('common.imprint')">{{ $t('common.imprint') }}</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>


<script>
export default {
  name: 'TheFooter',
  methods: {
    getCurrentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>
