<template>
  <section class="container py-5">
    <div>
      <h1>Pana Rent Car <span>Automobile</span></h1>
      <p>{{ $t('features.subtitle') }}</p>
      <div class="row no-gutters gx-0">
        <article class="col-md-4 mb-3 mb-md-0" aria-labelledby="simplicityTitle">
          <div class="feature-box greybox">
            <h2 id="simplicityTitle">{{ $t('features.simplicityTitle') }}</h2>
            <p>{{ $t('features.simplicityText') }}</p>
          </div>
        </article>
        <article class="col-md-4 mb-3 mb-md-0" aria-labelledby="eleganceTitle">
          <div class="feature-box greenbox">
            <h2 id="eleganceTitle">{{ $t('features.eleganceTitle') }}</h2>
            <p>{{ $t('features.eleganceText') }}</p>
          </div>
        </article>
        <article class="col-md-4 mb-3 mb-md-0" aria-labelledby="supportTitle">
          <div class="feature-box greybox">
            <h2 id="supportTitle">{{ $t('features.supportTitle') }}</h2>
            <p>{{ $t('features.supportText') }}</p>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeatureSection',
};
</script>

<style scoped>
.feature-box {
  padding: 20px;
  border-radius: 8px;
  color: #fff;
  height: 100%;
}

.greybox {
  background: #2b384a;
}

.greenbox {
  background-color: #47918a;
}

@media (min-width: 768px) {
  .feature-box {
    border-radius: 0;
  }
}
</style>
