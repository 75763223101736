<template>
  <div class="top-bar d-flex justify-content-center align-items-center flex-column flex-md-row">
    <p class="m-0 me-md-3">{{ $t('topBar.message')}}</p>
    <a href="/car-rental" class="btn btn-small btn-light my-1 my-md-0" :aria-label="$t('common.bookNow')">{{ $t('common.bookNow')}}</a>
  </div>
  <router-view/>
</template>

<script>
export default {
  mounted() {
    this.updateTitle();
  },
  watch: {
    $route() {
      this.updateTitle();
    }
  },
  methods: {
    updateTitle() {
      const nearestTitle = this.$route.meta.title;
      if (nearestTitle) {
        document.title = this.$t(nearestTitle);
      } else {
        document.title = this.$t('common.pageTitle'); // Fallback title
      }
    }
  }
};
</script>

<style>
.top-bar {
  background-color: #ffbd32;
  padding: 7px 0;
  text-align: center;
}
</style>
