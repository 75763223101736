import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'home.pageTitle'
    },
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: 'about.pageTitle'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      title: 'contact.pageTitle'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
  },
  {
    path: '/car-rental',
    name: 'car-rental',
    component: () => import(/* webpackChunkName: "about" */ '../views/VehiclesView.vue'),
  },
  {
    path: '/imprint',
    name: 'imprint',
    meta: {
      title: 'imprint.pageTitle'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ImprintView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    meta: {
      title: 'privacy.pageTitle'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
