<template>
  <nav :class="['navbar', 'navbar-expand-lg', 'bg-white', 'sticky-top']" role="navigation" aria-label="Main Navigation">
    <div class="container py-2">
      <a class="navbar-brand" href="#">
        <img src="@/assets/Logo-Pana-Rent-Car.jpg" alt="Pana Rent Car Logo" class="logo" width="150" height="auto"/>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" :aria-label="$t('nav.toggle')">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto mb-3 mb-md-0">
          <li class="nav-item">
            <router-link
                class="nav-link"
                :to="'/'"
                :aria-current="$route.path === '/' ? 'page' : null"
            >
              {{ $t('common.home') }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
                class="nav-link"
                :to="'/car-rental'"
                :aria-current="$route.path === '/car-rental' ? 'page' : null"
            >
              {{ $t('common.carRental') }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
                class="nav-link"
                :to="'/about'"
                :aria-current="$route.path === '/about' ? 'page' : null"
            >
              {{ $t('common.about') }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
                class="nav-link"
                :to="'/contact'"
                :aria-current="$route.path === '/contact' ? 'page' : null"
            >
              {{ $t('common.contact') }}
            </router-link>
          </li>
          <LanguageSwitcher class=""/>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
  components: {
    LanguageSwitcher
  },
  setup() {
    const isScrolled = ref(false);

    const handleScroll = () => {
      isScrolled.value = window.scrollY > 50;
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      isScrolled
    };
  }
};
</script>

<style scoped>
.navbar {
  transition: background-color 0.3s ease;
  z-index: 1000;
  width: 100%;
}

.sticky {
  position: fixed;
  top: 0;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.navbar .logo {
  height: 50px;
}

.nav-item:not(:last-child) {
  margin-right: 15px;
}

.nav-link {
  color: #000;
  position: relative;
  padding-bottom: 5px;
  transition: color 0.3s, border-bottom 0.3s;
}

.nav-link:hover {
  color: #01375e;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  background-color: #01375e;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-link:hover::after {
  transform: scaleX(1);
}

.router-link-active,
.router-link-exact-active {
  color: #01375e;
}

.router-link-active::after,
.router-link-exact-active::after {
  transform: scaleX(1);
}

@media (min-width: 992px) {
}
</style>
