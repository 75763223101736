<template>
  <div class="modal fade" id="placeModal" tabindex="-1" aria-labelledby="placeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="placeModalLabel">{{ $t(`popularPlaces.places.${place.id}.title`) }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <img :src="getImageSrc(place.imageSrc)" :alt="$t(`popularPlaces.places.${place.id}.title`)" class="img-fluid mb-3">
          <p class="text-start my-auto text-black px-5">{{ $t(`popularPlaces.places.${place.id}.description`) }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('popularPlaces.close') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import {getImageSrc} from "@/helper/getImageSrc";

export default {
  props: {
    place: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.modalInstance = new Modal(this.$el, {
      backdrop: 'static',
      keyboard: true
    });
  },
  methods: {
    getImageSrc,
    hideModal() {
      this.$emit('close');
      const modalElement = document.getElementById('placeModal');
      if (modalElement) {
        const modalInstance = Modal.getInstance(modalElement);
        if (modalInstance) {
          modalInstance.hide();
        }
      }
    }
  }
};
</script>
