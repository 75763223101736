import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {createI18n} from "vue-i18n";
import gr from "./locales/gr.json";
import de from "./locales/de.json";
import en from "./locales/en.json";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import './assets/css/style.css';


const i18n = createI18n({
    locale: localStorage.getItem('locale') || 'en',
    fallbackLocale: "en",
    messages: { gr, de, en },
    legacy: false
})

const app = createApp(App)
app.use(i18n)
app.use(router).mount('#app')

