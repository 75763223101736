<template>
  <div class="parallax-container" :style="{ backgroundImage: `url(${resolveBackgroundImage()})` }">
    <div class="parallax-content">
      <slot></slot> <!-- Platz für zusätzlichen Inhalt -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParallaxHero',
  props: {
    backgroundImage: {
      type: String,
      required: true
    },
    speed: {
      type: Number,
      default: 0.5 // Geschwindigkeit des Parallax-Effekts
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const parallaxContainer = this.$el;
      const scrolled = window.scrollY;
      const rate = scrolled * this.speed;

      parallaxContainer.style.backgroundPositionY = `${rate}px`;
    },
    resolveBackgroundImage() {
      try {
        return require(`@/assets/images/${this.backgroundImage}`);
      } catch (e) {
        console.error('Image not found:', this.backgroundImage);
        return '';
      }
    }
  }
};
</script>

<style scoped>
.parallax-container {
  position: relative;
  width: 100%;
  height: 400px; /* Höhe der Parallax-Komponente */
  overflow: hidden;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-position 0.5s ease;
}

.parallax-content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
